import { gsap, ScrollToPlugin, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger); 

export default ($) => ({ 
  scroll(elem){
    elem.on('click', function(e){
      e.preventDefault();
      let target = $(`.target-scroll${$(this).attr('href')}`).offset().top - $('header .top-header').outerHeight();

      elem.removeClass('active')
      $(this).addClass('active')
      gsap.to($(window), 1.2, {
          scrollTo: {
              y: target
          },
            ease: "Power2.easeOut",
          overwrite: 5
      });
    })
    
  }
})