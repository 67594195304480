import { gsap, TweenLite} from "gsap/all";
gsap.registerPlugin(TweenLite); 

export default ($) => ({    
    /*openPopup(id){              
        var elem = $('.popup'+id);     
        $('body').addClass('lock');  
        TweenLite.set(elem, {'display':'flex', opacity:0}); 
        TweenLite.to(elem, .3, {opacity:1,ease:'power2.out'});       
    },*/ 
    outsidePopup() {
        $('.popup').on('click', function(event) {          
            var $target = $(event.target);
            if(!$target.closest('.popup .inner-popup').length) {            
                TweenLite.to($(this), .3, {display:'none', opacity:0,ease:'power2.out'});
                $('body').removeClass('lock');
                $('.popup .video iframe').attr('src', 
                    $('.popup .video iframe').attr('src')
                );
                return false;
            }                       
        });
    },
    closePopupGeneral() {
        $('.popup .close-popup').on('click', function(event) { 
            TweenLite.to($(this).parents('.popup'), .3, {'display':'none','opacity':0,ease:'power2.out'});     
            $('body').removeClass('lock');   
            $('.popup .video iframe').attr('src', 
                $('.popup .video iframe').attr('src')
            );
            return false;              
        });
    },   
    popupVideo() {
        function openPopup(id){              
            var elem = $('.popup'+id);     
            $('body').addClass('lock');  
            TweenLite.set(elem, {'display':'flex', opacity:0}); 
            TweenLite.to(elem, .3, {opacity:1,ease:'power2.out'});       
        }
        $('.button-play').on('click',function(e){
            e.preventDefault();
            const idpopup = '#popup-video'
            openPopup(idpopup)            
        })
    },
    popupSlider() {
        function inductionSlider() {
            const slider = $('.popup-induction .popupSlider .slider');
            slider.slick({
                adaptiveHeight: false,
                infinite: false
            });
        } 
        function combiSlider() {
            const slider = $('.popup-combi .popupSlider .slider');
            slider.slick({
                adaptiveHeight: false,
                infinite: false
            });
        } 
        function ovenSlider() {
            const slider = $('.popup-oven .popupSlider .slider');
            slider.slick({
                adaptiveHeight: false,
                infinite: false
            });
        }       

        function openPopup(id){              
            var elem = $('.popup'+id);     
            $('body').addClass('lock');  
            TweenLite.set(elem, {'display':'flex', opacity:0}); 
            TweenLite.to(elem, .3, {opacity:1,ease:'power2.out'});       
        }
        $('.button-popup-induction').on('click',function(e){
            e.preventDefault();
            const idpopup = '#popup-induction'
            openPopup(idpopup) 
            inductionSlider();
        });
        $('.button-popup-combi').on('click',function(e){
            e.preventDefault();
            const idpopup = '#popup-combi'
            openPopup(idpopup) 
            combiSlider();
        });
        $('.button-popup-oven').on('click',function(e){
            e.preventDefault();
            const idpopup = '#popup-oven'
            openPopup(idpopup) 
            ovenSlider();
        });
        $('.button-pop-banner').on('click',function(e){
            e.preventDefault();
            const idpopup = '#popup-banner'
            openPopup(idpopup) 
        });
        
    },
    slideBasket(){
        $('.slider-matching').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                    breakpoint: 479,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        })
    },
    showPopup(id){              
        const elem = $('.popup'+id);     
        $('body').addClass('lock');  
        TweenLite.set(elem, {'display':'flex', opacity:0}); 
        TweenLite.to(elem, .3, {opacity:1,ease:'power2.out', onComplete:function () {
        }});       
    },
    closePopup(id){              
        const elem = $('.popup'+id);      
        TweenLite.to(elem, .3, {'display':'none','opacity':0,ease:'power2.out'});  
        $('body').removeClass('lock');      
    },
    

    init(){      
        this.outsidePopup()
        this.closePopupGeneral()
        this.popupVideo()
        this.popupSlider()
        this.slideBasket()
    }
})


