import { gsap} from "gsap";

export default ($) => ({ 
    scrollHeader(){
		const act = function(){
			if($('header').length){
				if($(window).scrollTop() > 120) {
					$('header').addClass('float');
				} else {
					$('header').removeClass('float');
				}	  
			} else {
				$('header').addClass('float');
			}
		}
		act();
	    $(window).scroll(function () {
            act();          
	    });

        $('header').hover(function(){
            $(this).addClass('view-menu')
        }, function(){
            $(this).removeClass('view-menu')
        })
	},
    menuHeader(){
        $('.main-header nav > ul > li').each(function() {
            if($(this).children('.drop-hover-menu').length){
                $(this).addClass('have-sub')
            }
        });
        $('.main-header .drop-hover-menu .col ul > li').each(function() {
            if($(this).children('ul').length){
                $(this).addClass('sub-2')
            }
        });
        if($(window).width() > 1279) {
            $('.main-header .drop-hover-menu .col ul li.sub-2').hover(function() {
                $(this).parents('.drop-hover-menu').css({'min-height': $(this).children('ul').height()+120});
                $(this).parents('.col').addClass('hov-sub');
            }, function () {
                $(this).parents('.col').removeClass('hov-sub')
                $(this).parents('.drop-hover-menu').css({'min-height': '0px'});
                
            })
        } else if($(window).width() < 767){
            $('.main-header nav .have-sub > a').on('click',function(e) {
                e.preventDefault();
                $(this).parents('nav').find('.have-sub').removeClass('act')
                $(this).parent().addClass('act')
                
            })
            $('.main-header .back-to > a').on('click',function(e) {
                e.preventDefault();
                $(this).parents('.have-sub').removeClass('act')
                
            })
            $('.main-header .sub-2 > a').on('click',function(e) {
                e.preventDefault();
                if(!$(this).hasClass('act')){
                    $(this).addClass('act')
                    $(this).parent().children('ul').stop().slideDown()
                } else {
                    $(this).removeClass('act')
                    $(this).parent().children('ul').stop().slideUp()
                }
                
            })
        } else {
            $('.main-header nav .have-sub > a').on('click',function(e) {
                e.preventDefault();
                $(this).parents('nav').find('.have-sub').removeClass('act')
                $(this).parent().addClass('act')
                
            })
            $('.main-header .sub-2 > a').on('click',function(e) {
                e.preventDefault();
                $(this).parents('nav').find('.sub-2').removeClass('act-sub-drop')
                $(this).parents('nav').find('.box-img').removeClass('left-act')
                $(this).parent().addClass('act-sub-drop')
                $(this).parents('.wrap-menu-sub').find('.box-img').addClass('left-act')
                
            })
        }
        
    },
    cartHeader(){
        $('.right-top-header .ico-cart,.right-top-header .ico-user').on('click',function(e) {
            e.preventDefault();
            const cartWarp= $(this).parent().find('.drop-u'),
                overlay = $(this).parent().find('.overlay-u');

				gsap.set(cartWarp, {'margin-top': '-100px', opacity: 0, display: 'none'});
				gsap.to(cartWarp, 0.4,{'margin-top': '0', opacity: 1, display: 'block',  ease: 'power3.easeOut', onComplete: function(){
					gsap.to(overlay, 0.4, {display: 'block', opacity: 1,delay: 1, ease: 'power3.easeOut'});
				}});
				$('body').addClass('lock');

            
        })
        $('.right-top-header .overlay-u').on('click',function() {
            const cartWarp= $(this).parents('.right-top-header').find('.drop-u');

            gsap.to(cartWarp, 0.4,{'margin-top': '-100px', opacity: 0, display: 'none', ease: 'power3.easeOut', onComplete: function(){
                gsap.to($(this), 0.4, {display: 'none', opacity: 0,delay: 1, ease: 'power3.easeOut'});
                $('body').removeClass('lock');
            }});            
        })
        $('.listing-product').each(function() {
            if($(this).hasClass('on-list-view')){
                $(this).find('.button.compare').removeClass('only-ico');
            } else {
                $(this).find('.button.compare').addClass('only-ico');
            }
            
        })
    },
    searchHeader(){
        $('.top-header .ico-search').on('click',function() {
            const wrapSearch= $('.wrap-search-fix');

            gsap.set(wrapSearch, {scale: .93, opacity: 0, display: 'none'});
            gsap.to(wrapSearch, 0.3,{scale:1, opacity: 1, display: 'block',  ease: 'power3.easeOut', onComplete: function(){
                wrapSearch.find('input[type="text"]').focus()
            }});
            $('body').addClass('lock');            
        })
        $('.wrap-search-fix .close-src').on('click',function(e) {
            e.preventDefault();
            const wrapSearch= $('.wrap-search-fix');
            gsap.to(wrapSearch, 0.3,{scale:.93, opacity: 0, display: 'none',  ease: 'power3.easeOut', onComplete: function(){
                $('body').removeClass('lock');
            }});
                        
        })
    },
    burgerMenu(){
        $('header .burger-ico').on('click',function() {
            if(!$(this).hasClass('act')){
                $(this).addClass('act')
                $(this).parents('header').find('.main-header').show()
                $('body').addClass('lock')

            } else {
                $(this).removeClass('act')
                $('body').removeClass('lock')
                $(this).parents('header').find('.main-header').hide()
            }       
        })
    },
    mobileIcon(){
        $('.mobile-icon-menu .profile-mobile,.mobile-icon-menu .cart-mobile').on('click', function(e){
            e.preventDefault()
            const target = $(this).attr('href'),
                cartWarp= $(this).parents('header').find('.drop-u'+target),
                overlay = $(this).parents('header').find(target +'.overlay-u');

            gsap.set(cartWarp, {'margin-top': '-100px', opacity: 0, display: 'none'});
            $('.burger-ico').removeClass('act')
            gsap.to($('.main-header'), 0.3,{display: 'none',ease: 'power3.easeOut', onComplete: function(){
                gsap.to(cartWarp, 0.4,{'margin-top': '0', opacity: 1, display: 'block',  ease: 'power3.easeOut'});
                gsap.to(overlay, 0.4, {display: 'block', opacity: 1,delay: 1, ease: 'power3.easeOut'});
            }})
        })

    },
    varHeight(){
		const setHeight = function(){
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		setHeight();
		$(window).on('load',function () {
			setHeight();          
		});
        $(window).on('resize',function () {
			setHeight();          
		});
	},
    init() {
        this.varHeight()
        this.menuHeader()
        this.cartHeader()
        this.searchHeader()
        this.burgerMenu()
        this.mobileIcon()
        this.scrollHeader()
	}
})