import * as $ from 'jquery';
import helper  from './libs/helper.js';
import headerFunction  from './libs/header-function.js';
import popup  from './libs/popup.js';
import scrollSection  from './libs/scroll-section.js';
import 'lazyload';

window.$ = $




$(document).ready(() => {
    let images = $(".lazyload");
    lazyload(images)
    
	helper($).init()
    headerFunction($).init()
    popup($).closePopupGeneral()
    popup($).outsidePopup()
	$('.h-banner figure img').responsiveImage({
        type:"image"
    });
    $('.homeSlider .slide figure img').responsiveImage2({
        type:"image"
    });

    scrollSection($).scroll($('.small-menu.scroll-menu a'))
    scrollSection($).scroll($('.to-scroll'))

    $('.video > span').on('click', function(){
        const vids = $(this).parent().find('iframe');
        vids.fadeIn();
    })

})

$.fn.responsiveImage = function (s) {
    let e ={
        type:undefined
    }
    e = $.extend(e,s);
    let elem = this, etype,
            action = function () {
                if(e.type == undefined){
                    etype = "background";
                }else{
                    etype = e.type;
                }
                let window_width;
                window_width = $(window).width();
                elem.each(function () {
                    let flag = false;
                    let images_url;
                    if (window_width > 1200 && $(this).attr('has_load') != 'large') {
                        images_url = $(this).attr('data-img-large');
                        $(this).attr('has_load', 'large');
                        flag = true;
                    } else if (window_width <= 1200 && window_width >= 1024 && $(this).attr('has_load') != 'medium') {
                        images_url = $(this).attr('data-img-medium');
                        $(this).attr('has_load', 'medium');
                        flag = true;
                    } else if (window_width < 1024 && window_width >= 0 && $(this).attr('has_load') != 'small') {
                        images_url = $(this).attr('data-img-small');
                        $(this).attr('has_load', 'small');
                        flag = true;
                    }
                    if (images_url == undefined) {
                        images_url = $(this).attr('data-img-large');
                        $(this).attr('has_load', 'large');
                    }

                    if (flag){
                        if(etype == "background"){
                            $(this).css('background-image', 'url('+images_url+')');
                        }else{
                            $(this).attr('src', images_url);
                        }     
                    }     
                });

            }
    action();
   
    var resizeTimer;

    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            action();
        }, 500);
    });
}

$.fn.responsiveImage2 = function (s) {
    let e ={
        type:undefined
    }
    e = $.extend(e,s);
    let elem = this, etype,
            action = function () {
                if(e.type == undefined){
                    etype = "background";
                }else{
                    etype = e.type;
                }
                let window_width;
                window_width = $(window).width();
                elem.each(function () {
                    let flag = false;
                    let images_url;
                    if (window_width > 579 && $(this).attr('has_load') != 'large') {
                        images_url = $(this).attr('data-img-large');
                        $(this).attr('has_load', 'large');
                        flag = true;
                    } else if (window_width < 580 && window_width >= 0 && $(this).attr('has_load') != 'small') {
                        images_url = $(this).attr('data-img-small');
                        $(this).attr('has_load', 'small');
                        flag = true;
                    }
                    if (images_url == undefined) {
                        images_url = $(this).attr('data-img-large');
                        $(this).attr('has_load', 'large');
                    }

                    if (flag){
                        if(etype == "background"){
                            $(this).css('background-image', 'url('+images_url+')');
                        }else{
                            $(this).attr('src', images_url);
                        }     
                    }     
                });

            }
    action();
   
    var resizeTimer;

    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            action();
        }, 500);
    });
}

window.openPopup = (id) => {
    popup($).showPopup(id)
}
window.closePopup = (id) => {
    popup($).closePopup(id)
}