import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';


export default ($) => ({
	numberonly() {
      	$(".numberonly").keypress(function (e) {
            if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
               return false;
            }
        });
    },
    labelForm(){
        const box = $('.form-group');
        const intext = 'input[type=text],input[type=email],input[type=number],input[type=password],textarea,select';
        const text = box.find(intext);
        text.each(function(){
            if($(this).val() !== ""){
                $(this).parents('.form-group').find('> .placeholder').addClass('focus');
            }
        });
        text.on('focus', function(){
            $(this).parents('.form-group').find('> .placeholder').addClass('focus');
        });
        text.focusout(function(){
            if($(this).val() == ""){
                $(this).parents('.form-group').find('> .placeholder').removeClass('focus');
            }
        });
    },
    selectFilled() {
		$('.form-group select').on('focus',function(){
            if (!$(this).val()) {
                $(this).parents('.form-group').find('> .placeholder').removeClass('focus');
            }else{
                $(this).parents('.form-group').find('> .placeholder').addClass('focus');
            }
        })
        $('.form-group select').focusout(function(){
            if (!$(this).val()) {
                $(this).parents('.form-group').find('> .placeholder').removeClass('focus');
            }else{
                $(this).parents('.form-group').find('> .placeholder').addClass('focus');
            }
        })
    },
    inputFilled(){
		$('.form-group input, .form-group textarea').on('focus',function(){
            if (!$(this).val()) {
                $(this).parents('.form-group').find('> .placeholder').removeClass('focus');
            }else{
                $(this).parents('.form-group').find('> .placeholder').addClass('focus');
            }
        })
        $('.form-group input, .form-group textarea').focusout(function(){
            if (!$(this).val()) {
                $(this).parents('.form-group').find('> .placeholder').removeClass('focus');
            }else{
                $(this).parents('.form-group').find('> .placeholder').addClass('focus');
            }
        })
	},
    floatingContact(){
        $('.float-contact .close').click(function(){
            $(this).parents('.float-contact').slideUp();
        })  
    },
    setDatepicker(){
        $('.datepicker').datepicker({ dateFormat: 'dd MM yy' });
    },
    viewPassword(){
        $('.view-password').on('click', function(){
            if(!$(this).hasClass('view')){
                $(this).addClass('view')
                $(this).parent().children('input').attr('type','text')
            } else {
                $(this).removeClass('view')
                $(this).parent().children('input').attr('type','password')
            }
        })
    },

    tooltipHOver(){
        $('.have-tooltip').each(function(){
            const pos = $(this).offset()     
            const rh = ($(window).width() - (pos.left + $(this).outerWidth()))
            if(pos.left < 180){
                $(this).addClass('to-right')
            } else if(rh < 180){
                $(this).removeClass('to-right')
                $(this).addClass('to-center')
            } else {
                $(this).removeClass('to-right')
                $(this).removeClass('to-center')
            }
        })
        $('.have-tooltip').hover(function(){
            $(this).find('.tooltip-data').stop().show(150);

        }, function(){
            $(this).find('.tooltip-data').stop().hide(150);
        })
    },
	init() {
		this.numberonly();
		// this.selectFilled();
		// this.inputFilled();
        this.floatingContact();
        this.setDatepicker();
        this.viewPassword();
        this.labelForm();
        this.tooltipHOver();
	}
});